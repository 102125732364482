
import notification from "@/services/notificationService";
import Vue from "vue";
import api from "../services/dataService";

export default Vue.extend({
  name: "PlaceOrder",
  data() {
    return {
      order: {
        salutation: "",
        firstName: "",
        lastName: "",
        companyName: "",
        billingAddress: "",
        email: "",
        phoneNo: "",
        billingCountry: ""
      },
      countries: ["Pakistan", "Germany", "United Kingdom", "Saudi Arabia"]
    };
  },
  methods: {
    submitOrder: function(e: Event): void {
      api
        .post("/orders/", this.order)
        .then(res => {
          notification.success("Ordered placed");
        })
        .catch(err => {
          notification.error(err.message);
        });
    }
  }
});
